/* src/App.css */
.App {
  text-align: center;
  color: #333333;
}

.content {
  padding: 2rem;
}

.App.scrolled {
  background-color: #f0f0f0; /* Change background on scroll */
}

p {
  font-size: 16px;
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
  text-align: justify;
}

@media (max-width: 1000px) {
  .mainTitle {
    padding: 10px;
  }

  #about {
    padding: 10px !important;
  }

  #about > div {
    padding: 10px !important;
  }

  .sectionAd{
    padding: 10px !important;
    flex-direction: column;
  }

  #management > div {
    padding: 10px !important;
  }

  #instructors {
    padding: 10px !important;
  }

  #instructors > div {
    padding: 10px !important;
  }

  #newsAndEvents > div {
    padding: 10px !important;
  }

  #newsAndEvents {
    padding: 1rem 2rem
  }

  #Gallery {
    padding: 0rem 2rem;
    padding-bottom: 2rem;
  }

  #Gallery > div {
    padding: 10px !important;
  }

  .slider__item img {
    height: 50vh !important;
  }
}

@media (max-width: 1205px){
  .course .mainContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact .mainContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f0f0f000; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #0000006d; /* Dark red thumb */
  border-radius: 20px;
  border: 2px solid #f0f0f0; /* Optional border */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #000000b8; /* Slightly lighter color on hover */
}