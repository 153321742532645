.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainContent {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    gap: 20px;
}

.mainContent .left{
    flex: 1;
}

.mainContent .form{
    flex: 1;
}

.contentWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.nameEmailDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.buttonWrapper{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}