.footer {
    display: flex;
    flex-wrap: wrap;
    background-color: #FFD700;
    color: #333333;
    padding: 20px 40px;
    justify-content: space-between;
    align-items: flex-start;
    font-family: "Poppins", sans-serif;
  }
  
  .logoSection,
  .linksSection,
  .socialSection,
  .contactSection {
    flex: 1 1 200px;
    margin: 10px;
  }
  
  .logo {
    width: 80px;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 0.9rem;
    color: #8B0000;
  }
  
  .linksSection h4,
  .socialSection h4,
  .contactSection h4 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #8B0000;
  }
  
  .navLinks {
    list-style: none;
    padding: 0;
  }
  
  .navLinks li {
    margin: 5px 0;
  }
  
  .navLinks a {
    color: #333333;
    text-decoration: none;
  }
  
  .navLinks a:hover {
    color: #ffcc00;
  }
  
  .socialIcons {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .socialIcons a {
    font-size: 1.5rem;
    color: #333333;
    transition: color 0.3s;
  }
  
  .socialIcons a:hover {
    color: #8B0000;
  }
  
  .contactSection address {
    font-style: normal;
    font-size: 0.9rem;
    line-height: 1.5;
  }
  
  .contactSection a {
    color: #8B0000;
    text-decoration: none;
  }
  
  .contactSection a:hover {
    text-decoration: underline;
  }
  
  .copyright {
    text-align: center;
    width: 100%;
    font-size: 0.8rem;
    margin-top: 20px;
    color: #333333;
  }
  