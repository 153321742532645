/* src/Home.css */
.home {
    overflow: hidden; /* Prevent scroll on body */
}

.welcome-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-image: url('../public/image1.jpg'); /* Replace with your image URL */
    background-size: cover;
    background-position: center;
    color: white;
    text-align: center;
    animation: fadeIn 1s ease;
}

.welcome-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.5);
    padding: 2rem;
    width: 100vw;
    height: 100vh;
}

.welcome-text > span{
    font-size: 60px;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.btn {
    background-color: #3a3f47;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn:hover {
    background-color: #5a5f67;
}

/* Info sections */
.info-section {
    padding: 2rem;
    text-align: center;
    animation: slideIn 1s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.mainTitle {
    position: relative;
    color: #af0000;
    font-size: 24px;
    font-weight: 700;
    padding: 0 0 10px 0;
    margin-bottom: 20px;
    width: fit-content;
    font-family: "Poppins", sans-serif;
}

.mainTitle:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 2px;
    background-color: #FFD700;
}

.mainTitle:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 25px;
    width: 40px;
    height: 2px;
    background-color: #FFD700;
}

.sectionAd{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 2rem 5rem;
    background-color: #ffd900a6;
}

.managementImg{
    height: 250px;
    width: auto;
}

.imgCardWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}
