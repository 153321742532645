.course{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainContent {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px;
    gap: 20px;
}

.tableWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.first {
    font-family: "Poppins", sans-serif;
    text-align: left;
}

.third {
    width: 100%;
}